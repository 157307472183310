import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmptyJson} from '../../util/utils';
import {InitialFilters} from '../../util/DefaultInitialValues';
import POSSelectView from '../../components/POSSelectView/POSSelectView';
import config_settings from '../../assets/images/Config_settings.png';
import './invoice.scss';

const Roster = () => {

	const [filters, setFilters] = useState(InitialFilters);
	const [urls, setAllURLs] = useState([]);

	const [selectedUrl, setSelectedUrl] = useState("");

	const {
    	office_id = "",
    	emp_legal_entity_id = "",
    	emp_id_pattern = "",
    	date = "",
      POS = ""
  	} = filters || {};

  	const updateSelectValue = (name, value) => {
    	setFilters({...filters, [name]: value});
  	}

  	const {
	    details,
	    loading,
	    error
  	} = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));


  	useEffect(() => {
  		getAllRosterLinks();
  	}, []);

  	 useEffect(() => {
      if(details && !isEmptyJson(details)) {
        setFilters(details); 
      }
    }, [details]);

  	const getAllRosterLinks = async () => {
  		const URL= "https://sheets.googleapis.com/v4/spreadsheets/1gFDOopqaX-JNS45BztluUEhDFN7-PHtJW9_0f2rfSvI/values/RosterMasterSheet?key=AIzaSyBVyvVqrQBA3DXJZtpNioHqp9A7Db-Ow5A";
 
        //setLoading(true);

        return await fetch(URL, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
    	  .then((res) => {
          //Close Dialog
          setAllURLs(res?.values);
        })
        .catch((error) => {
          console.log('error getAllRosterLinks', error);
          //setLoading(false);
        });
  	}

  	useEffect(() => {
  		getURL(office_id);
  	}, [urls]);

  	const onSelectPOS = (value) => {
  		getURL(value);
  	}

  	const getURL = (pos = "") => {
  		const filterItem = urls.filter(item => item[1] === pos);

  		if(filterItem && filterItem.length > 0) {
  			const URL = filterItem[0][4];

  			if(URL)
  			sendData(URL)
  		}
  	}

  	const sendData = (url) => {
  		const iframe = document.getElementById('google-sheet'); 

      const data = { message: url };
      iframe.contentWindow.postMessage(data, '*'); // Replace '*' with specific origin if needed
  	}

  	return (

  		<div className="row align-items-center">
          <div className="col-lg-3 col-md-3 col-sm-12 align-items-center">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <div> 
                    <img src={config_settings} className="page_logo" /> 
                  </div>

                  <div>
                    <div className="page_h1_heading">Roster</div>
                    <div className="page_le_text">{filters && filters.emp_legal_entity_id}</div>
                  </div>

                </div>
              </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 align-items-center">
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <POSSelectView name="office_id" value={office_id} onInputChange={(e) => onSelectPOS(e.target.value)} />
          </div>
	    	              
	        <div id="iframe-container">
	          <iframe id="google-sheet" src="../OA_Roster_Editable.html"></iframe>
	        </div>
      </div>
  )
}

export default Roster;