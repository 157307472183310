import * as React from 'react';
import Box from '@mui/material/Box';
import { TextareaAutosize } from '@mui/base';

const TextAreaView = (props) => {
  
  const { isError = false, label="", name = "", value = "", isShowLabel,  onChange, placeholder = "Access Office IDs", ...rules} = props;

  const onHandleChange = (e) => {
    onChange(e);
  }

  return (
   <Box>
      <label style={{maxWidth : '40%', marginBottom : "10px"}} className="field">
      {label}
      {rules.required && <span className="text-danger">&nbsp;*</span>}
      </label>
      <TextareaAutosize
        maxRows={4}
        resize="none"
        placeholder={placeholder}
        name={name}
        className="form-control"
        value={value}
        aria-label="maximum height"
        style={{ width: '100%', height: '100%', 'minHeight' : '60px' }}
        {...rules}
        onChange={(e) => onHandleChange(e)}
      />
    </Box>
  );
}


export default TextAreaView;
