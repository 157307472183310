import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {InitialFilters} from '../../util/DefaultInitialValues';
import POSSelectView from '../POSSelectView/POSSelectView';
import PayrollSelectView from '../PayrollSelectView/PayrollSelectView';
import {saveFilters} from '../../actions/filters.actions.js';

import DatePickerView from '../DatePickerView';
import './filter-view.css';

const FilterView = (props) => {
  const saveFilterDataAction = saveFilters;
  const dispatch = useDispatch();
	const {clsName = "", filters = InitialFilters, onHandleGo, isShow = false, isShowPOSFilter = true, isShowPayrollFilter = true, isMaxForMonth = true} = props;

  const [localFilters, setLocalFilters] = useState(filters ? filters : InitialFilters);

  const {
    office_id = "",
    emp_legal_entity_id = "",
    emp_id_pattern = "",
    startDate = "",
    endDate = ""
  } = localFilters || {};

  const onSubmit = () => {
    dispatch(saveFilterDataAction(localFilters));
    onHandleGo(localFilters);
  }

  const onInputChange = (e) => {
    setLocalFilters({...localFilters, [e.target.name] : e.target.value});
  }

  const updateSelectValue = (name, value) => {
    setLocalFilters({...localFilters, [name] : value});
  }
  
  const onDatePickerUpdate = (date = []) => {
    setLocalFilters({...localFilters, ["startDate"] : date[0], ["endDate"]: date[1]});
  }

  useEffect(() => {
    if(filters && JSON.stringify(filters) !== "{}") {
      setLocalFilters(filters);
    }
  }, [filters]);

	return (
		<div className="filter_container">
      
      {isShow && <div className="mx-lg-3 py-2">
        <DatePickerView 
          className={`date_picker ${clsName}`} 
          type="month" 
          onDatePickerUpdate ={(d) => onDatePickerUpdate(d)} value = {[startDate, endDate]}
          isMaxForMonth = {isMaxForMonth}
        />
      </div>
      }

      {isShowPOSFilter && <div className="mx-lg-3 py-2">
        <POSSelectView name="office_id" value={office_id} onInputChange={(e) => onInputChange(e)}/>
      </div>}

      {isShowPayrollFilter && <div className="mx-lg-3 py-2">
        <PayrollSelectView name="emp_id_pattern" value={emp_id_pattern} onInputChange={(e) => onInputChange(e)} />
      </div>}
      
      <button className="btn btn-outline-danger py-2 go-btn" onClick={onSubmit} disabled={false}>Go</button>
    </div>
	)
}

export default FilterView;