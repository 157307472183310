import React, {useEffect, useState} from 'react';
import { BrowserRouter, Routes, Link  } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { Helmet } from 'react-helmet';
import {baseURL} from '../../actions/Constants';
import { useNavigate } from 'react-router-dom';

import CustomizedSnackbars from '../../components/CustomizedSnackbars';

import FilterView from '../../components/filterView/FilterView';
import Loading from '../../components/loading/Loading';

import TableView from '../../components/table/TableView';
import Tooltip from '@mui/material/Tooltip';
import {isEmptyJson} from '../../util/utils';

import {getDateFromTimeStamp, reverseDate} from '../../util/DateTimeConverter';
import {InitialEmployee} from '../../util/DefaultInitialValues';
import EditEmployee from './EditEmployee';
import EditBulkShift from './EditBulkShift';
import { PersonAdd, Visibility } from "@mui/icons-material";

import AddBulkUsersDialog from './AddBulkUsersDialog';
import config_settings from '../../assets/images/Config_settings.png';

const EmployeesList = () => {
  const navigate = useNavigate();
  //const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [employes, setEmployes] = useState([]);
  const [isShow, setShow] = useState(false);
  const [empDetails, setEmpDetails] = useState(null);
  const [filters, setFilters] = useState(null);
  
  const [isAddBulkUsers, setAddBulkUsers] = useState(false);
  const [CheckedRows, setSelectedRows] = useState([]);

  const [isShowShiftDialog, setShowShiftDialog] = useState(false);

  const {
    details: OfficeList
  } = useSelector(({ office: { details} }) => ({ details }));
    
  const {
    details
  } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

  const getUsersByLE = async () => {
    if(filters.emp_legal_entity_id === "") {
      navigate("/configure-device");
      return;
    }
    setLoading(true);
    const URL=`${baseURL}/getUsersByLE_Filter`;

    let obj = {};
    obj.legal_entity_id = filters.emp_legal_entity_id;
    obj.office_id = office_id === "all" ? "" : office_id; ;
    obj.emp_id_pattern = emp_id_pattern === "all" ? "" : emp_id_pattern;
    
    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj), // body data type must match "Content-Type" header
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => {
      const resp = response;

      const {status = ""} = resp;
      setLoading(false);
     
      if(status === 200 || status === 201) {
        return response.json().then(res => {
          setEmployes(res?.data);
        });
      } else {
        //Error
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error getUsersByLE', error);
      setError(true);
    });
  }

  useEffect(() => {
    if(details && !isEmptyJson(details)) {
      setFilters(details);
    }
  }, [details]);

  useEffect(() => {
    document.title = "Employee List - PC Chandra";
    //getUsersByLE();
  }, []);

  useEffect(() => {
    if(filters !== null && emp_legal_entity_id !== "") {
      getUsersByLE();
    }
  }, [filters]);

  const onUpdateSuccess = () => {
    setShow(false);
    getUsersByLE();
  }

  const showDetails = (value) => {
    if(value !== null) {
      const filterRow = employes.filter((item, index) => item.emp_id === value);
      if(filterRow && filterRow.length > 0) {

        const details = filterRow[0];
        setEmpDetails(details);
      }
    } else {
      setEmpDetails(InitialEmployee);
    }
    setShow(true);
  }

  const navigateToLog = (userId = "") => {
    navigate(`/logs/${userId}`);
  }

  const columns = [
    {
      name: "emp_id",
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button onClick={() => showDetails(value)} className="ax-button red">
              {value}
            </button>
          );
        }
      }
    },
    {
      name: "emp_name",
      label: 'Name',
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
            
            return (
              <span>
                {value}
              </span>
            );
        }
      }
    },
    {
      name: "emp_email",
      label: 'Email',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_gender",
      label: 'Gender',
      options: {
       filter: true,
       sort: true
      }
    },
    {
      name: "emp_phone_no",
      label: 'Mobile',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_role",
      label: 'Role',
      options: {
       filter: true,
       sort: true,
      }
    },
    {
      name: "emp_designation",
      label: 'Designation',
      options: {
       filter: true,
       sort: true
      }
    },
    {
      name: "shift_time",
      label: 'In Time',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const emp_ID = tableMeta.rowData[0];
          let  val = "-";
          
          const filterEmpList = employes.filter(emp => emp?.emp_id === emp_ID);
          let filterEmp = {};
          let emp_config = {};

          if(filterEmpList && filterEmpList.length) {
            filterEmp = filterEmpList[0];
           
            if(filterEmp && filterEmp?.emp_configuration && filterEmp?.emp_configuration !== "") {
              emp_config = JSON.parse(filterEmp?.emp_configuration);
      
              val = (emp_config && emp_config?.shift_in_time) || "-";
            }
          }

          return (
            <span>
              {val}
            </span>
          );
        }
      }
    },
    {
      name: "shift_in_time",
      label: 'Out Time',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const emp_ID = tableMeta.rowData[0];
          let  val = "-";
          
          const filterEmpList = employes.filter(emp => emp?.emp_id === emp_ID);
          let filterEmp = {};
          let emp_config = {};

          if(filterEmpList && filterEmpList.length) {
            filterEmp = filterEmpList[0];

            if(filterEmp.emp_configuration !== "") {
              emp_config = JSON.parse(filterEmp?.emp_configuration);
              val = emp_config.shift_out_time;
            }
          }

          return (
            <span>
              {val}
            </span>
          );
        }
      }
    },
    {
      name: "emp_base_office_id",
      label: 'Base Office',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {

          const filterOffice = OfficeList.filter((ofc, index) => ofc.office_id === value);
          const ofcName = filterOffice.length > 0 ? filterOffice[0].office_name : "";
          return (
            <span>
              {ofcName}
            </span>
          );
        }
      }
    },
    {
      name: "emp_id",
      label: 'Log',
      options: {
        filter: false,
        sort: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
             <Link to={`/logs/${value}`} target="_blank" className="red">
                <Visibility />
              </Link>
          );
        }
      }
    }
  ];

  const AddButton = () => (
    <Tooltip disableFocusListener title='Add Employee'>
      <button className="MuiButtonBase-root MuiIconButton-root ax-button" onClick={() => showDetails(null)} >
        <PersonAdd />
      </button>
    </Tooltip>
  );

  const options = {
    selectableRows: true,
    filter: false,
    download: true,
    print: true,
    rowsPerPage: 100,
    customToolbar: AddButton,
    sortOrder: {
      name: 'emp_device_uuid',
      direction: 'asc'
    },
    downloadOptions: {
      filename: `PCC-Employees-${reverseDate(getDateFromTimeStamp(Date.now()))}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const onInputChange = (e) => console.log(e);
  const titleVal = "Employes - OneAttendance";
  const le = details && details.emp_legal_entity_id || "SMMC";

  const {
    office_id = "",
    emp_legal_entity_id = "",
    emp_id_pattern = ""
  } = filters || {};

  const handleSMSBtnClicked = async (selectedRows) => {
    setSelectedRows(selectedRows);
    setShowShiftDialog(true);
  }

  const onUpdateShift = async (data = {}) => {
    setShowShiftDialog(false);
    const filterArray = CheckedRows?.data && CheckedRows?.data.map((item, index) => employes[item.dataIndex]);

    await filterArray.map((emp, index) => {
      updateShiftDetails(emp, data);
    });
  }

  const updateShiftDetails = async (emp = {}, data = {}) => {
    if(emp?.emp_configuration !== "") {
      
      const URL= `${baseURL}/updateUser`;
      setLoading(true);
      
      const empConfig = JSON.parse(emp?.emp_configuration);
      empConfig.shift_in_time = data?.shift_in_time;
      empConfig.shift_out_time = data?.shift_out_time;
      emp.emp_configuration = JSON.stringify(empConfig);

      return await fetch(URL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(emp), // body data type must match "Content-Type" header
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((res) => {
        //Close Dialog
        setLoading(false);
      })
      .catch((error) => {
        console.log('error updateUser/insertUser', error);
        setLoading(false);
      });
    }
  }

  return (
    <>
      <Helmet>
      <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
      </Helmet>

      {(isLoading || isLoading) ? <Loading /> :
      (
        <div className="home">
          <div className="container-fluid">
            <div className="row align-items-center">

                <div className="col-lg-3 col-md-12 col-sm-12">
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center">
                        <div> 
                          <img src={config_settings} className="page_logo" alt = "" /> 
                        </div>

                        <div>
                          <div className="page_h1_heading">Employees</div>
                          <div className="page_le_text">{filters && filters.emp_legal_entity_id}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12">
                  <FilterView filters={filters} onHandleGo={data => setFilters(data)} />
                </div>

                <div className="d-flex justify-content-end py-3">
                  <button className="btn btn-outline-danger" onClick={() => setAddBulkUsers(true)}>Add Bulk Users</button>
                </div>
            </div>
                
            <div className="row mt-2">
              <div className="col-lg-12">
                {/*<Table headers={columns} rowData={employes} options={options} />*/}

                <TableView
                  clsName = "employee"
                  title="" 
                  columns={columns} 
                  data={employes} 
                  onSMSBtnClicked = {(selectedRows) => handleSMSBtnClicked(selectedRows)} 
                  tableOptions = {options} 
                />
              </div>
            </div>
          </div>
          {isShow && <EditEmployee details={empDetails} isOpen = {isShow} onUpdateSuccess={onUpdateSuccess} onHandleCloseDialog = {() => setShow(false)} le = {le} />}
        </div>
      )}

      {isAddBulkUsers && <AddBulkUsersDialog isOpen = {isAddBulkUsers} onHandleClose = {() => setAddBulkUsers(false)} />}
      {isShowShiftDialog && <EditBulkShift isOpen={isShowShiftDialog} onUpdateShift = {data => onUpdateShift(data)} onHandleCloseDialog = {() => setShowShiftDialog(false)} />}
        
      {isError && <CustomizedSnackbars />}
    </>
  );
}
 
export default EmployeesList;