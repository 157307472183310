import {
  GET_WEB_ACCESS_CONTROL_START,
  GET_WEB_ACCESS_CONTROL_SUCCESS,
  GET_WEB_ACCESS_CONTROL_FAILURE,
  baseURL
} from '../../actions/Constants';

const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
};

export default function webAccessReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_WEB_ACCESS_CONTROL_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_WEB_ACCESS_CONTROL_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_WEB_ACCESS_CONTROL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}