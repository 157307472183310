import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import { Helmet } from 'react-helmet';
import {isEmptyJson} from '../../util/utils';
import {baseURL} from '../../actions/Constants';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import {getTimeStampsFromDate, getEndDateEpoc} from '../../util/DateTimeConverter';
import Loading from '../../components/loading/Loading';
import TabView from '../../components/tabView/TabView';
import FilterView from '../../components/filterView/FilterView';
import PendingLeave from './PendingLeaves';
import ApproveLeaves from './ApproveLeaves';
import RejectLeaves from './RejectLeaves';
import leave from '../../assets/images/ic_leave_approval.png';

const Leaves = () => {
	const [filters, setFilters] = useState(null);
	
	const [viewIndex, setViewIndex] = useState(0);
	const [allLeaves, setAllLeaves] = useState([]);
	const [pendingLeaves, setPendingLeaves] = useState([]);
	const [rejectedLeaves, setRejectedLeaves] = useState([]);
	const [approvedLeaves, setApprovedLeaves] = useState([]);

	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

	const TabsData = ["Pending", "Approved", "Rejected"];
	const titleVal = "Leaves - OneAttendance";

	const {
	    details
  	} = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));
  	
  	const {
        details: loggedAdminDetails
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));


  	useEffect(() => {
	    if(details && !isEmptyJson(details)) {
	      	setFilters(details);
	    }
  	}, [details]);

  	useEffect(() => {
	    if(filters !== null && emp_legal_entity_id !== "") {
	      	onFetchLeaveDetails();
	    }
  	}, [filters]);

  	useEffect(() => {
    // Add a class to the body tag
    document.body.classList.add('leave');

    // Cleanup: Remove the class when the component unmounts
    return () => {
      document.body.classList.remove('leave');
    };
  }, []);

	const updateLeaveStates = (data = []) => {
		const pendingList = data.filter(lvs => lvs.leave_application_status === "PENDING");
      	const approveList = data.filter(lvs => lvs.leave_application_status === "APPROVED");
      	const rejectedList = data.filter(lvs => lvs.leave_application_status === "REJECTED");
      	setAllLeaves(allLeaves);
      	setPendingLeaves(pendingList);
      	setApprovedLeaves(approveList);
      	setRejectedLeaves(rejectedList);
	} 

	const getReporteeLeaveDataByDateRange = async () => {
		setLoading(true);
		const URL = `${baseURL}/getReporteeLeaveDataByDateRange`;
		const startTimeStampValue = getTimeStampsFromDate(startDate);
	    const endTimeStampValue = getEndDateEpoc(endDate);

	    let obj = {};
	    obj.emp_manager_emp_id = loggedAdminDetails?.emp_id;
		obj.leave_starting_date_timestamp_from = "" + startTimeStampValue;
		obj.leave_starting_date_timestamp_to = "" + endTimeStampValue;
		obj.legal_entity_id = emp_legal_entity_id;

		//console.log("getReporteeLeaveDataByDateRange", obj);

		return await fetch(URL, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
      		body: JSON.stringify(obj) // body data type must match "Content-Type" header
			
    	})
      	.then(response => {
		    const resp = response;

		    const {status = ""} = resp;
		    setLoading(false);

		    if(status === 200) {
		      return response.json().then(res => {
		      	const allLeaves = res?.data || [];
		      	if(Array.isArray(allLeaves)) {
		      		updateLeaveStates(allLeaves);
		      	}
		      });
		    } else {
		      //Error
		      setLoading(false);
		      setError(true);
		    }
  		})
  		.catch((error) => {
		  	setLoading(false);
		  	setError(true);
		    console.log('error getReporteeLeaveData', error);
		});
	}

	const onFetchLeaveDetails = () => {
		getReporteeLeaveDataByDateRange();
	}

	const {
	    emp_legal_entity_id = "",
	    startDate = "",
	    endDate = ""
  	} = filters || {};
  	
	return (
		<>
	      	<Helmet>
	        	<title>{titleVal}</title>
	        	<meta name="description" content="Login meta"></meta>
	        </Helmet>
	      	
	      	<div className=" mb-3 filter_section leave">
		        <div className="d-flex justify-content-between align-items-center mb-3">
		          <div className="d-flex align-items-center">
		            <div> 
		              <img src={leave} className="w-100" alt= "" /> 
		            </div>

		            <div>
		              <div className="page_h1_heading ps-3">Leave Approvals</div>
		              <div className="page_le_text">{filters && filters?.emp_legal_entity_id}</div>
		            </div>
		          </div>
		        </div>

	        	<FilterView
	        		clsName= "leave"
	        		filters={filters} 
	        		onHandleGo={data => setFilters(data)} 
	        		isShow={true}
	        		isShowPOSFilter = {false}
	        		isShowPayrollFilter = {false}
	        		isMaxForMonth = {false} 
	        	/>
	      	</div>

	      	<TabView TabsData = {TabsData} onTabClick = {(idx) => setViewIndex(idx) } />
	      	{viewIndex === 0 && <PendingLeave  leaves = {pendingLeaves} mgr_id = {loggedAdminDetails?.emp_id} onFetchLeaveDetails = {onFetchLeaveDetails} />}
	      	{viewIndex === 1 && <ApproveLeaves leaves = {approvedLeaves} mgr_id = {loggedAdminDetails?.emp_id} onFetchLeaveDetails = {onFetchLeaveDetails} />}
	      	{viewIndex === 2 && <RejectLeaves  leaves = {rejectedLeaves} mgr_id = {loggedAdminDetails?.emp_id} onFetchLeaveDetails = {onFetchLeaveDetails} />}

	      	{isError && <CustomizedSnackbars />}
	      	{isLoading && <Loading />}
	    </>
	)
}

export default Leaves;