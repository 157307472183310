import React, {useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import './checkboxView.css';

const CheckBoxView = (props) => {
  const {value = false, onChange, isDisabled = false } = props;
  const [checked, setChecked] = React.useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled = {isDisabled}
      sx={{
          color: red[600],
          '&.Mui-checked': {
            color: red[600],
          },
        }}
    />
  );
}

export default CheckBoxView;

