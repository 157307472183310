import { combineReducers } from 'redux';
import adminReducer from './Admin/admin.reducer';
import loggedUserAllRolesReducer from './AdminAllRoles/loggedUsersRoles.reducer';
import filterReducer from './Filters/filter.reducer';
import officeReducer from './Offices/office.reducer';
import payrollReducer from './Payrolls/payroll.reducer';
import webAccessReducer from './WebAccess/webAccess.reducer';
import leReducer from './legalEntity/le.reducer';

const appReducer = combineReducers({
	loggedUserAllRoles: loggedUserAllRolesReducer,
	loggedAdmin: adminReducer,
	filter: filterReducer,
	office: officeReducer,
	payroll: payrollReducer,
	webAccess: webAccessReducer,
	le: leReducer
});

const rootReducer = (state, action) => {
	return appReducer(state, action);
};

export default rootReducer;