import React, {useEffect, useState} from "react";
//import AttendanceTemplate from './AttendanceTemplate';
import TableView from '../../components/table/TableView';
import {getFormattedTime} from '../../util/utils';
import {msToTime, reverseDate} from '../../util/DateTimeConverter';
import people from '../../assets/images/people.png';

const ApprovedAttendance = (props) => {
	const {attendance = []} = props;
	
	const [approvedAttendanceList, setApprovedAttendanceList] = useState(attendance);

  useEffect(() => {
    if(attendance) {
      setApprovedAttendanceList(attendance);
    }
  }, [attendance])

  const columns = [
    {
      name: "emp_id",
      label: 'Emp ID',
      options: {
        filter: true,
        sort: true,
        viewColumns : false,
        customBodyRender: (value, tableMeta, updateValue) => {
         
          const selectedEmp = attendance.find(item => item?.emp_id === value) || {};

          return (
            <div className="fw-bold d-flex align-items-center">
              <img src={selectedEmp?.emp_profile_pic || people} style={{width: '40px', height: '40px'}} className="rounded-circle" alt="" />
              <div className="mx-3">
                <div className="fw-bold">{value}</div>
                <div className="fw-bold">{selectedEmp?.emp_name}</div>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: "attendance_date_timestamp_formatted",
      label: 'Date',
      options: {
        filter: true,
        sort: true,
        viewColumns : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {reverseDate(value)}
            </div>
          );
        }
      }
    }, 
    {
      name: "attendance_trail",
      label: 'Recorded In - OUT Time',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const trail = JSON.parse(value);
          const {sign_in = 0, sign_out = 0}  = trail?.login_trail?.[0] || {};

          return (
            <div className="d-flex align-items-center">
              {getFormattedTime(sign_in)} <span className="fs-5 px-2">-</span> {getFormattedTime(sign_out)}
            </div>
          );
        }
      }
    },
    {
      name: "attendance_trail",
      label: 'Recorded Duration',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const trail = JSON.parse(value);
    
          const {sign_in = 0, sign_out = 0}  = trail?.login_trail?.[0] || {};
          const actual_duration_val = (sign_in && sign_out && sign_in < sign_out) ? msToTime(sign_out, sign_in) : '--:--';

          return (
            <div>
              {actual_duration_val}
            </div>
          );
        }
      }
    },
    {
      name: "manual_edit_status",
      label: 'Manual In - OUT Time',
      options: {
        filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const {manual_sign_in = "", manual_sign_out = ""} = JSON.parse(value);
            
            return (
              <div className="d-flex align-items-center">
                  {getFormattedTime(manual_sign_in)} <span className="fs-5 px-2">-</span> {getFormattedTime(manual_sign_out)}
        </div>
            );
          }
      }
    },
    {
      name: "manual_edit_status",
      label: 'Manual Duration',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const {manual_sign_in = "", manual_sign_out = ""} = JSON.parse(value);
          const modified_duration_val = (manual_sign_in && manual_sign_out && manual_sign_in < manual_sign_out) ? msToTime(manual_sign_out, manual_sign_in) : "--:--";

          return (
            <div className="d-flex align-items-center">
              {modified_duration_val}
            </div>
          );
        }
      }
    }, 
    {
      name: "manual_edit_reason",
      label: 'Reason',
      options: {
        filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {value || "-"}
              </div>
            );
          }
      }
    },
    {
      name: "manual_edit_approve_date_timestamp",
      label: 'Approved Date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {getFormattedTime(value) || "-"}
            </div>
          );
        }
      }
    },
    {
      name: "manual_edit_approver_remarks",
      label: 'Manager Remarks',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {value || "-"}
            </div>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: false,
    filter: false,
    download: true,
    print: true,
    rowsPerPage: 25,
    sortOrder: {
      name: 'attendance_date_timestamp',
      direction: 'asc'
    }
  };

  return (
    <div className="my-4">
      <TableView
        clsName = "employee"
        title="" 
        columns={columns} 
        data={approvedAttendanceList} 
        tableOptions = {options} 
      />
    </div>
  )
}

export default ApprovedAttendance;