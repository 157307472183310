import React, {useEffect, useState} from 'react';
import FloatSelect from '../SelectElement';
import {useSelector} from 'react-redux';
import SelectView from '../../components/Select/SelectView';

const OfficeSelectView = (props) => {
	const {
        name = "", 
        title = "", 
        setUpdateValue, 
        label = "", 
        value = "", 
        isRequired=false, 
        isError = false, 
        isInvalid = false, 
        isDisabled = false,
        onInputChange
    } = props;

    const {
        details
    } = useSelector(({ office: { details} }) => ({ details }));

	return (
        <SelectView 
        	name={name} 
        	isRequired={isRequired} 
            isError={isError}
            isInvalid={isInvalid}
        	value={value} label = {label} 
        	keyVal = "office_id" 
        	labelVal = "office_name" 
        	list = {details} 
        	onInputChange = {(e) => onInputChange(e)}
            isDisabled = {isDisabled}
        />
	)
}

export default OfficeSelectView;