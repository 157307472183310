import React, {useEffect, useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './select.scss';

const SelectView = (props) => {
  const {
    isError = false, 
    classVal = "", 
    label = "", 
    name="", 
    isRequired = false, 
    list = [], 
    keyVal="value",  
    value="", 
    title="", 
    labelVal="label",
    onInputChange,
    isStandard = true,
    isDisabled = false
  } = props;

  const [val, setVal] = React.useState('');
 
  const handleChange = (event) => {
    setVal(event.target.value);
    onInputChange(event);
  };

  useEffect(() => {
    if(value) setVal(value);
  }, [value]);

  return (
    <div className="select">
      <FormControl variant={`${isStandard ? "standard" : "outlined"}`} sx={{ m: 1, minWidth: 120 }} error={isError} required={isRequired} >
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={val}
          defaultValue={val}
          onChange={handleChange}
          label={label}
          name={name}
          className={`${classVal}`}
          disabled = {isDisabled}
        >
          {Array.isArray(list) && list.length > 1 && <MenuItem value="all">
            <em>All</em>
          </MenuItem>}

          {Array.isArray(list) && list.map((item, index) => {
            return <MenuItem value={item[keyVal]}>{item[labelVal]}</MenuItem>
          })}
        </Select>
      </FormControl>
      
    </div>
  );
}

export default SelectView;
