import React, {useState, useEffect} from 'react';
import { DatePicker } from 'rsuite';
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';
import './datepicker.scss';

const DatepickerView = (props) => {
  const {onDatePickerUpdate, value = new Date(), isMaxForMonth = true} = props;
  const [selectedDate, setSelectedDate] = useState(value);

  const getTomorrow = (date) => {
    const today = new Date();
    //today.setHours(0, 0, 0, 0);  // Set time to midnight for date comparison
    return date > today;
  }

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  return (
    <div className="datepicker">
    <DatePicker
      value={selectedDate}
      cleanable={false} 
      format='dd-MM-yyyy'
      style={{width: '100%', marginRight: "50%"}}
      shouldDisableDate = {getTomorrow}
      onChange={(date) => onDatePickerUpdate(date)}
    />
    </div>
  );
};

export default DatepickerView;