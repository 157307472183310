import React, {useEffect, useState, useRef} from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Loading from '../../components/loading/Loading';
import {useDispatch} from 'react-redux';
import {InitialFilters} from '../../util/DefaultInitialValues';
import {saveAdmin, saveLoggedAdminRoles} from '../../actions/admin.actions.js';
import {getAllOfficesByLegalEntity} from '../../actions/offices.actions.js';
import {getConfigurationByLE, saveLegalEntity, saveFilters} from '../../actions/filters.actions.js';
import {addZeroBefore, isPhoneNumberValid} from '../../util/DateTimeConverter';
import {generateLEList} from '../../util/utils';
import {baseURL} from '../../actions/Constants';
import {PCC_OneAttendance_version} from '../../actions/Constants';

import wave from '../../assets/wave.png';
import emp_icon from '../../assets/images/emp.png';
import pcc_logo from '../../assets/ic_logo_oneattendance5.png';

import './login.scss';

const Login = () => {
	const navigate = useNavigate();
  const dispatch = useDispatch();
	const [otp, setOtp] = useState(new Array(4).fill(''));
	const [isShowOTPField, setIsShowOTPField] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [inValidOTP, setInvalidOTP] = useState(false);
	const [isInvalidPhone, setInvalidPhone] = useState(false);
	const [timer, setTimer] = useState(59);
	const [isShowTimer, setShowTimer] = useState(false);
	const id = useRef(null);
  
  const [emp, setEmployee] = useState(null);
  const [filters] = useState(InitialFilters);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isSubmit, setSubmitState] = useState(false);
  const [isInvalidUser, setIsInvalidUser] =  useState(false);
  const [isUserExist, setUserExist] = useState(true);

  const [fullEmpDetails, SetFullEmpDetails] = useState(null);
  
  const saveAdminAction = saveAdmin;
  //const saveLoggedAdminRolesAction = saveLoggedAdminRoles;
  const saveFilterDataAction = saveFilters;
  const saveLegalEntityAction = saveLegalEntity;

  useEffect(() => {
    return () => {
      clear();
    }
  }, []);

  useEffect(() => {
    if (emp && isSubmit) {
      //const isValidUser = validateUser(emp);
      if(/*isValidUser && */emp?.emp_phone_no === phoneNumber) {
        setIsShowOTPField(true);
        startTimer();
        //const isManagerLoggedIn = isManager(emp);
        dispatch(getAllOfficesByLegalEntity(emp?.emp_legal_entity_id));
        dispatch(getConfigurationByLE(emp?.legal_entity_id));
        getOTP();
        setIsInvalidUser(false);
      } else {
        setIsInvalidUser(true);
        setSubmitState(false);
      }
      setSubmitState(false);
    }
  }, [emp]);

  const getEmployeeByPhone = async (phone) => {
    const URL = `${baseURL}/getEmployeeByPhoneNumber`;
    setLoading(true);

    try {
      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emp_phone_no: phone }),
      });
      const res = await response.json();

      if (res?.data?.length === 0) {
        setUserExist(false);
      } else {
        isAdmin(res.data);
        SetFullEmpDetails(res.data);
      }
      
      setError(false);
    } catch (error) {
      console.log('Error getting employee by phone:', error);
      setError(true);
      setInvalidPhone(true);
    } finally {
      setLoading(false);
    }
  };

  const isAdmin = (userList = []) => {
    const list = userList;
    setEmployee(list[0]);
    
    /*const adminUser = list.filter((item, index) => validateUser(item) && item.emp_phone_no === phoneNumber);
   
    if(adminUser.length > 0) {
      setEmployee(adminUser[0]);
    } else {
      setEmployee(list[0]);
    }
    */
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setInvalidPhone(false);
    setUserExist(true);
    setInvalidOTP(false);

    if (!isShowOTPField) {
      clear();
      setEmployee(null);

      if (isPhoneNumberValid(phoneNumber)) {
        setSubmitState(true);
        getEmployeeByPhone(phoneNumber);
      } else {
        setInvalidPhone(true);
      }
    } else {
      const hasEmptyOTP = otp.some((digit) => digit === '');
      if (hasEmptyOTP) {
        setInvalidOTP(true);
      } else {
        verifyOTP(otp);
        // navigate('/'); // remove this in actual
      }
    }
  };

  const getOTP = () => {
    const URL = `${baseURL}/getOTP?phone_number=${phoneNumber}`;
    fetch(URL)
    .then(res => res.json())
    .then(res => {

    })
    .catch(error => {
      console.log('error', error);
    })
  };

  const verifyOTP = (otp) => {
    const receivedOTP = otp.join("");

    fetch(`${baseURL}/verifyOTP`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify({ emp_phone_no: phoneNumber, otp: receivedOTP }),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(res=> res.json())
    .then(res => {
      const {status = "", data = {}} = res;
      const {message = ""} = data || {};
      //if (status === "SUCCESS") {
      if (status === "SUCCESS" && message === "OTP verified success") {
        const LEList = generateLEList(fullEmpDetails);
        dispatch(saveLegalEntityAction(LEList));

        if(emp?.emp_role?.toLowerCase().includes('manager')) {
          filters.office_id = emp?.emp_base_office_id;
          filters.emp_legal_entity_id = emp?.emp_legal_entity_id;
        } else {
          filters.emp_legal_entity_id = "";
        }

        dispatch(saveLoggedAdminRoles(fullEmpDetails));
        dispatch(saveAdminAction(emp));
        dispatch(saveFilterDataAction(filters));
        localStorage.setItem("PCC_OneAttendance_version", PCC_OneAttendance_version);
        navigate('/configure-device');
      } else {
        setInvalidOTP(true);
      }
    })
    .catch(error => {
      console.log('error', error);
    })
  }

  const handleKeydown = (e, index) => {
    const keyCode = e.which;

    if (keyCode === 37 || keyCode === 38) { // Left or Up arrow
      if (index > 0) {
        const prevElement = document.getElementById(`digit-${index}`);
        prevElement.focus();
      }
    } else if (keyCode === 39 || keyCode === 40) { // Right or Down arrow
      if (index < 3) {
        const nextElement = document.getElementById(`digit-${index + 2}`);
        nextElement.focus();
      }
    }
  };

  const handlePhoneNumber = (e) => {
  	setPhoneNumber(e.target.value);
  }
 
  const handleChange = (e, index) => {
    const value = e.target.value;

    // Only process numeric input
    if (isNaN(value)) return;

    // Update OTP state
    setOtp((prevOtp) =>
      prevOtp.map((digit, idx) => (idx === index ? value : digit))
    );

    // Move focus to the next input if the current one is filled
    if (value && index < 3) {
      document.getElementById(`digit-${index + 2}`).focus();
    }
  };


	const startTimer = () => {
    setShowTimer(true);
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
  }

	const clear = () => {
      if(id) window.clearInterval(id.current);
	};

	useEffect(() => {
    if (timer === 0 || !isShowOTPField) {
      clear();
      setTimer(59);
      setShowTimer(false);
    }
    else {
      setTimer(timer);
    }
	}, [timer, isShowOTPField]);

	const handleChangeView = () => {
		setIsShowOTPField(false);
		handleResendOtp();
	}

	const handleResendOtp = () => {
		setOtp(new Array(4).fill(''));
	}

  const titleVal = "Login - OneAttendance";
  const hrefVal = window.location.href;

  return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>

      <div className="login">
        <div className="wave">
          <img src={wave} alt = "" />
        </div>
        
        <div className="pcc_logo_container">
          <img src={pcc_logo} alt = "" className="pcc_logo" />
          <div className="ps-3 powered_by_text"><span className="powered_by">powered by</span> <span className="fw-bold smmc_text">SMM:C</span></div>
        </div>

        <div className="footer-text">© SMM:C · Contact · Privacy & terms</div>
        <div className="login_container">
          <div className="login-content flex-column">
            <div className="d-flex align-items-center my-3">
                <span className="pe-2 logo_black inter_regular">Welcome to </span>
                <div className="logo_black"><span className="logo_red">One</span> Attendance</div> 
            </div>
            
            <div className="mt-5">
              <div className="d-flex align-items-center">
                <div> 
                  <img src={emp_icon} className="pe-3" alt = "" /> 
                </div>

                <div>
                  <div className="dialog_h2_heading">Sign In</div>
                  <div className="mt-3">
                    <div className="dialogSubText">Please use your registered</div>
                    <div className="dialogSubText">phone number to sign in using OTP </div>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={e=>handleSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>

    					{isShowOTPField && <p>We have sent One time password to your register phone number. <button className="ax-button bold italic red" onClick={handleChangeView}>Change</button></p>}
            
              {!isShowOTPField && <div>
           		 
           		  <div className="py-3">
         		   		<input type="text" disabled={isShowOTPField} placeholder="Phone Number" onChange={e => handlePhoneNumber(e)} className="w-100 p-3 phone" />
           		  </div>
       		    </div>}

    	        {isInvalidPhone && <p className="red">! Invalid Phone Number</p>}
              {(!isUserExist || isInvalidUser) && <p>
                  Phone number not registered.Contact your IT team
                  or contact <a href="mailto:admin@oneattendance.com">admin@oneattendance.com</a>
                </p>
              }

        	    {isShowOTPField &&
  			        <div className="flex otp-container my-3">
                  {otp.map((val, index) => {
                    return (
                    <input
                       className="otp-field"
                       id={`digit-${index+1}`}
                       type="text"
                       name={`digit-${index+1}`}
                       maxLength="1"
                       key={index}
                       value={val}
                       focus={index === 0}
                       onKeyDown={e => handleKeydown(e, index)} 
                       onChange={e => handleChange(e, index)}
                    />
                  );
                })}
              </div>
        		}

  					{isShowOTPField && <div className="mt-3">
  						Not received OTP?
  						{isShowTimer && <span>&nbsp; Resend OTP in 00 : {addZeroBefore(timer)}</span>}
  						{!isShowTimer && <button type="button" className="ax-button bold italic red" onClick={handleResendOtp}>&nbsp; Resend</button>}
  					</div>}
                
  			    {inValidOTP && <p className="red mt-3">! Invalid OTP</p>}

  	        {<input type="submit" className="1login_btn btn btn-outline-danger border-1 rounded-2 my-3" value={`${isShowOTPField ? 'Login': 'Get OTP'}`} />}
  	      </form>
  	    </div>
      </div>
    </div>
    {isLoading && <Loading />}
    {isError && <CustomizedSnackbars />}
  </>
	)
};

export default Login;