import React, {useEffect, useState} from "react";
import {useSelector} from 'react-redux';
import { Helmet } from 'react-helmet';
import {isEmptyJson} from '../../util/utils';
import {baseURL} from '../../actions/Constants';

import ApprovedAttendance from './ApprovedAttendance';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import FilterView from '../../components/filterView/FilterView';
import Loading from '../../components/loading/Loading';
import TabView from '../../components/tabView/TabView';
import PendingAttendance from './PendingAttendance';
import RejectedAttendance from './RejectedAttendance';
import {getTimeStampsFromDate, getEndDateEpoc} from '../../util/DateTimeConverter';
import attendance_approval_icon from '../../assets/images/attendance-approval.png';

const AttendanceApproval = () => {
	const [filters, setFilters] = useState(null);
	
	const [viewIndex, setViewIndex] = useState(0);
	const [allAttendance, setAllAttendance] = useState([]);
	const [pendingAttendance,  setPendingAttendance] = useState([]);
	const [rejectedAttendance, setRejectedAttendance] = useState([]);
	const [approvedAttendance, setApprovedAttendance] = useState([]);

	const [isLoading, setLoading] = useState(false);
  	const [isError, setError] = useState(false);

	const TabsData = ["Pending", "Approved", "Rejected"];
	const titleVal = "Attendance Approvals - OneAttendance";

	const {
	    details
  	} = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));
  	
  	const {
        details: loggedAdminDetails
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));


  	useEffect(() => {
	    if(details && !isEmptyJson(details)) {
	      	setFilters(details);
	    }
  	}, [details]);

  	useEffect(() => {
	    if(filters !== null && emp_legal_entity_id !== "") {
	      	onFetchEditedAttendaceDetails();
	    }
  	}, [filters]);

  	useEffect(() => {
	    // Add a class to the body tag
	    document.body.classList.add('leave');
	    onFetchEditedAttendaceDetails();

	    return () => {
	      document.body.classList.remove('leave');
	    };
  }, []);

	const updateAttendanceStates = (data = []) => {
		const pendingList = data.filter(lvs => lvs.manual_edit_approve_status === "PENDING");
      	const approveList = data.filter(lvs => lvs.manual_edit_approve_status === "APPROVED");
      	const rejectedList = data.filter(lvs => lvs.manual_edit_approve_status === "REJECTED");
      	setAllAttendance(data);
      	setPendingAttendance(pendingList);
      	setApprovedAttendance(approveList);
      	setRejectedAttendance(rejectedList);
	} 

	const getReporteeAttendanceEditData = async () => {

		if(!emp_legal_entity_id) return;
        setLoading(true);
        setError(false);
        const URL = `${baseURL}/getReporteeAttendanceEditDataByDate`;

        const startTimeStampValue = getTimeStampsFromDate(startDate);
	    const endTimeStampValue = getEndDateEpoc(endDate);
        
        const obj = {};
	    obj.emp_manager_emp_id = loggedAdminDetails?.emp_id;
		obj.startdate_timestamp = "" + startTimeStampValue;
		obj.enddate_timestamp = "" + endTimeStampValue;
		obj.legal_entity_id = emp_legal_entity_id;

		console.log("getReporteeAttendanceEditDataByDate --->", obj);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
		.then((resp) => {
			setLoading(false);
		    const {code = ""} = resp;
		    if(code === 200 || code === 201) {
               const allEditedAttendance = resp?.data || [];
		      	if(Array.isArray(allEditedAttendance)) {
		      		console.log(allEditedAttendance)
		      		updateAttendanceStates(allEditedAttendance);
		      	}
            } else {
                //Error
                setError(true);
            }
		})
        .catch((error) => {
            setLoading(false);
            setError(true);
        });
    };

	const onFetchEditedAttendaceDetails = () => {
		getReporteeAttendanceEditData();
	}

	const {
	    emp_legal_entity_id = "",
	    startDate = "",
	    endDate = ""
  	} = filters || {};
  	
	return (
		<>
	      	<Helmet>
	        	<title>{titleVal}</title>
	        	<meta name="description" content="Login meta"></meta>
	        </Helmet>
	      	
	      	<div className=" mb-3 filter_section leave">
		        <div className="d-flex justify-content-between align-items-center mb-3">
		          <div className="d-flex align-items-center">
		              <img src={attendance_approval_icon}  style={{width: '80px'}} alt = "" /> 
		            <div>
		              <div className="page_h1_heading ps-3">Attendance Approvals</div>
		              <div className="page_le_text ps-3">{filters && filters?.emp_legal_entity_id}</div>
		            </div>
		          </div>
		        </div>

	        	<FilterView
	        		clsName= "leave"
	        		filters={filters} 
	        		onHandleGo={data => setFilters(data)} 
	        		isShow={true}
	        		isShowPOSFilter = {false}
	        		isShowPayrollFilter = {false}
	        		isMaxForMonth = {false} 
	        	/>
	      	</div>

	      	<TabView TabsData = {TabsData} onTabClick = {(idx) => setViewIndex(idx) } />
	      	{viewIndex === 0 && <PendingAttendance  attendance = {pendingAttendance} mgr_id = {loggedAdminDetails?.emp_id} fetchAttendanceDetails = {onFetchEditedAttendaceDetails} />}
	      	{viewIndex === 1 && <ApprovedAttendance attendance = {approvedAttendance} mgr_id = {loggedAdminDetails?.emp_id} />}
	      	{viewIndex === 2 && <RejectedAttendance  attendance = {rejectedAttendance} mgr_id = {loggedAdminDetails?.emp_id}  />}

	      	{isError && <CustomizedSnackbars />}
	      	{isLoading && <Loading />}
	    </>
	)
}

export default AttendanceApproval;