import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getConfigurationByLE} from '../../actions/filters.actions.js';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {baseURL} from '../../actions/Constants';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Input from '../../components/Input';
import Loading from '../../components/loading/Loading';
import {isTextValid} from '../../util/DateTimeConverter';

import emp_icon from '../../assets/images/emp.png';
import circle_logo from '../../assets/images/circle.png';

const EditEmpRole = (props) => {
    const dispatch = useDispatch();

    const { isOpen = false , details = {}, onHandleCloseDialog} = props;
    const {
        details: payrollDetails
    } = useSelector(({ payroll: { details} }) => ({ details }));

    const {
        details: filterDetails
    } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

    const initErrorState = {
        nameError: false
    };

    const [errors, setErrors] = useState(initErrorState);
    const [payroll, setPayroll] = useState(details);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(isOpen);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [isError, setError] = useState(false);
    const [allConfigData, setConfigData] = useState([]);
    const [isFormSubmit, setFormSubmit] = useState(false);
    const [isValidForm, setValidForm] = useState(true);
    
    const isEdit = details.emp_role_id !== "";
    useEffect(() => {
        if (!isValidForm) {
            const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
            if (firstInvalidEle) firstInvalidEle.focus();
            setValidForm(true);
        }
    }, [isValidForm]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
        setConfigData(payrollDetails);
    }, [payrollDetails]);
  
    const handleClose = (event, reason) => {
       // if (reason && reason == "backdropClick") return;
        setOpen(false);
        onHandleCloseDialog();
    };

    const onInputChange = (e) => setPayroll({...payroll, [e.target.name] : e.target.value});

    const validate = () => {
        let isFormValid = true;
        let isinValidName = false; 
        let isinValidDescription = false;
        let isinValidPayroll = false;
        let isinValidDays = false;

        if (!isTextValid(emp_role_id)) {
            isFormValid = false;
            isinValidName = true;
        }
        if (!isTextValid(emp_role_name)) {
            isFormValid = false;
            isinValidDescription = true;
        }

        setErrors({...errors,
            nameError: isinValidName
        });

        setValidForm(isFormValid);
        return isFormValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
     
        const isFormValid = validate();
        if (isFormValid) {
            if(isEdit) {
                updateConfigurationByLE();
            } else {
                addConfigurationByLE();
            }
            setFormSubmit(true);
        }
    };

    const addConfigurationByLE = async () => {
        let config_Data = JSON.parse(allConfigData[0].legal_entity_configuration);
        const todayEpoc = new Date().getTime();
        payroll.emp_role_id = "" + todayEpoc;
        payroll.emp_permission_level = "" + todayEpoc;
        config_Data.emp_role_details[config_Data.emp_role_details.length] = payroll;

        const obj = config_Data;
        const URL=`${baseURL}/updateConfigurationByLE`;
      
        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then((res) => {
            //Close Dialog
            setError(false);
            if(filterDetails && filterDetails.emp_legal_entity_id !== "") {
                dispatch(getConfigurationByLE(filterDetails?.emp_legal_entity_id)).then(() => {
                    onHandleCloseDialog();
                    setLoading(false);
                });
            }
        })
        .catch((error) => {
            console.log('error updateConfigurationByLE', error);
            setLoading(false);
            setError(true);
        });
    };

    const updateConfigurationByLE = async () => {
        let config_Data = JSON.parse(allConfigData[0].legal_entity_configuration);
    
        const index = config_Data.emp_role_details.findIndex(x => x.emp_role_id === payroll.emp_role_id);
        config_Data.emp_role_details[index] = payroll;

        const obj = config_Data;
        const URL=`${baseURL}/updateConfigurationByLE`;

        setLoading(true);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then((res) => {
          //Close Dialog
            setError(false);
            if(filterDetails && filterDetails.emp_legal_entity_id !== "") {
                dispatch(getConfigurationByLE(filterDetails?.emp_legal_entity_id)).then(() => {
                    onHandleCloseDialog();
                    setLoading(false);
                });
            }
        })
        .catch((error) => {
            console.log('error updateConfigurationByLE', error);
            setLoading(false);
            setError(true);
        });
    };

    const {
       emp_role_name = "Sales",
       emp_role_id = "1"

    } = payroll || {};

    const title = isEdit ? 'Edit Emp Role' : 'Add Emp Role';

    return (
        <>
            {isLoading ? <Loading /> :
                (
                <Dialog 
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={isOpen}
                    onClose={handleClose}
                    aria-label=""
                    className="md"
                   // onBackdropClick="false"
                >
               
                    <div className="container p-lg-5 p-3">
                        <div className="d-flex align-items-center">
                            <img src={circle_logo} className="circle_logo" />
                            <div className="logo_black ps-3"><span className="logo_red">One</span> Attendance</div> 
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                           
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div> 
                                      <img src={emp_icon} className="pe-2" /> 
                                    </div>

                                    <div>
                                        <div className="dialog_h2_heading">{title}</div>
                                        <div className="dialogSubText mt-3">
                                            <div>Please edit the information you want to</div>
                                            <div>update the settings, in the form below</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                
                            </div>
                        </div>

                        <form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate>
                            <div className="row">
                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Role Id"
                                        name="emp_role_id"
                                        value={emp_role_id}
                                        errorFieldName="emp_role_id"
                                        isInvalid={errors.nameError}
                                        isError={errors.nameError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)}
                                        isDisabled={isEdit ? true : false}
                                    />
                                </div>

                                <div className="col-lg-6 py-2">
                                    <Input
                                        label="Role Name"
                                        name="emp_role_name"
                                        value={emp_role_name}
                                        errorFieldName="Job Role Name"
                                        isInvalid={errors.nameError}
                                        isError={errors.nameError}
                                        isRequired={true}
                                        onInputChange={(e) => onInputChange(e)} 
                                    />    
                                </div>
                            </div>

                            <div className="d-flex justify-content-end pt-3">
                                <button type="button" className="btn btn-outline-danger me-3 mt-3" onClick={handleClose}>Cancel</button>
                                <button type="submit" className="btn btn-danger mt-3 me-3">{isEdit ? 'Update' : 'ADD'}</button>
                            </div>
                        </form>
                    </div>
                </Dialog>)
            }
            {isError && <CustomizedSnackbars />}
        </>
    )
};

export default EditEmpRole;