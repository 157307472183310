import {useState, useEffect} from 'react';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Input from '../../components/Input';
import SelectView from '../../components/Select/SelectView';
import Loading from '../../components/loading/Loading';
import {baseURL} from '../../actions/Constants';

import { 
    AttendanceType,
    isPhoneNumberValid
} from '../../util/utils';

const OfficePreviewDetails = (props) => {
    const {office = {}, setOffice, isEdit=false, handleClose, setStepper} = props;
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateOffice();
    } 

    const onInputChange = (e) => {
        setOffice({...office, [e.target.name] : e.target.value});
    }

    const updateOfcConfigData = (e) => {
        setOffice({...office, office_configuration: {...office_configuration, [e.target.name]: e.target.value}});
    }

    const updateOffice = async () => {
        const URL= isEdit ? `${baseURL}/updateoffice` : `${baseURL}/insertoffice`;
        setLoading(true);

        if(office_configuration !== "") {
            office.office_configuration = JSON.stringify(office_configuration);
        }

        if(office_geofencing_conf !== "") {
            office.office_geofencing_conf = JSON.stringify(office_geofencing_conf);
        }

        if(office_wifi_conf !== "") {
            office.office_wifi_conf = JSON.stringify(office_wifi_conf);
        }

        if(office_beacon_conf !== "") {
            office.office_beacon_conf = JSON.stringify(office_beacon_conf);
        }

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(office), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json',
            }
        })
        .then(response => {
            const resp = response;
            const {status = ""} = resp;
            
            setLoading(false);

            if(status === 200 || status === 201) {
               //Close Dialog
                setError(false);
                handleClose();
            } else {
                console.log('error insertoffice/updateoffice');
                setError(true);
            }
        })
        .catch((error) => {
            console.log('error insertoffice/updateoffice', error);
            setLoading(false);
            setError(true);
        });
    }

    const {
        office_id = "",
        office_name = "",
        office_phone= "",
        legal_entity_id = "",
        office_address = "",
        office_configuration = {
            office_off_day: "",
            attendance_method: ""
        },
        office_email = "",
        office_description = "",
        office_geofencing_conf = "",
        office_wifi_conf = "",
        office_beacon_conf = []
    } = office || {};

    const {
        lat = "",
        lng = "",
        radius = "300",
        address = "",
        city = "",
        state = "",
        country = "",
        postalCode = ""
    } = office_geofencing_conf || {};

    return (
        <>
        <form  onSubmit={e=>handleSubmit(e)} autoComplete="off" noValidate className="p-lg-5 p-md-5 p-2">
            <h6>Office Preview Details</h6>

            <p className="pt-2 fw-bold">Finish setting up your office</p>
            <p>Please review the configuration of this office</p>

            <p className="fw-bold">Office Details</p>

            <div className="border rounded border-secondary p-3 my-2">
                <div className="d-flex align-items-center">
                    <div>Office ID : &nbsp;</div>
                    <div>{office_id}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Office Name : &nbsp;</div>
                    <div>{office_name}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Address : &nbsp;</div>
                    <div>{office_address}</div>
                </div>
                 <div className="d-flex align-items-center">
                    <div>Office Email : &nbsp;</div>
                    <div>{office_email}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Office Phone : &nbsp;</div>
                    <div>{office_phone}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Legal Entity : &nbsp;</div>
                    <div>{legal_entity_id}</div>
                </div>

                <div className="d-flex align-items-center">
                    <div>Closed on : &nbsp;</div>
                    <div>None</div>
                </div>

                <div className="d-flex align-items-center">
                    <div>Attendance Method : &nbsp;</div>
                    <div>{office.office_configuration.attendance_method}</div>
                </div>

                <div className="d-flex align-items-center">
                    <div>Description : &nbsp;</div>
                    <div>{office_description}</div>
                </div>

            </div>

            <p className="pt-3 fw-bold">Configured Wi-Fi Details</p>
            <div className="border rounded border-secondary p-3 my-2">
                <div className="d-flex flex-column">
                    <div>Configured  WiFi : &nbsp;</div>
                    <div>{JSON.stringify(office_wifi_conf)}</div>
                </div>
            </div>

            <p className="pt-3 fw-bold">Configured Location Details</p>
            <div className="border rounded border-secondary p-3 my-2">
                <div className="d-flex align-items-center">
                    <div>Latitude : &nbsp;</div>
                    <div>{lat}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Longitude : &nbsp;</div>
                    <div>{lng}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Radius : &nbsp;</div>
                    <div>{radius}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Address : &nbsp;</div>
                    <div>{address}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>City : &nbsp;</div>
                    <div>{city}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>State : &nbsp;</div>
                    <div>{state}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Country : &nbsp;</div>
                    <div>{country}</div>
                </div>
                <div className="d-flex align-items-center">
                    <div>Postal code : &nbsp;</div>
                    <div>{postalCode}</div>
                </div>

            </div>

            <div className="d-flex justify-content-end pt-3 px-2">
                <button type="button" className="btn btn-outline-danger mt-3 mr-3 px-5" onClick={() => setStepper(3)}>Back</button>
                <button type="submit" className="btn btn-danger ms-3 mt-3 px-5">Submit</button>
            </div>
        </form>
        {isLoading && <Loading />}
        {isError && <CustomizedSnackbars showAlert = {(val) => setError(false)} />}
        </>
    )
}

export default OfficePreviewDetails;