import React, {useEffect, useState} from "react";
import TableView from '../../components/table/TableView';
import ApproveRejectLeaveDialog from './ApproveRejectLeaveDialog';
import {getDateFromTimeStamp} from '../../util/DateTimeConverter';

import approve from '../../assets/images/approve.png';
import reject from '../../assets/images/reject.png';

const PendingLeaves = (props) => {

	const {leaves = [], mgr_id = "", onFetchLeaveDetails} = props;
	const [pendingLeaveList, setPendingLeaveList] = useState(leaves);
	const [selectedLeave, setSelectedLeave] = useState(null);
	const [isShowApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
	const [isApprove, setApprove] = useState(false);

	const columns = [
	    {
	        name: "emp_id",
	        label: 'Emp ID',
	        options: {
	        	filter: true,
	        	sort: true,
	        	customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
	                	<div className="fw-bold">
	                  		{value}
	                	</div>
	              	);
	          	}
	        }
	    },
	    {
	        name: "emp_name",
	        label: 'Emp Name',
	        options: {
	        	filter: true,
	        	sort: true,
	        	customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
	                	<div className="fw-bold">
	                  		{value}
	                	</div>
	              	);
	          	}
	        }
	    },
      	{
	        name: "leave_description",
	        label: 'Reason',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            return (
		            	<div>
		              		{value}
						</div>
		            );
	          	}
	        }
      	}, 
      	{
	        name: "leave_starting_date_timestamp",
	        label: 'From Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_ending_date_timestamp",
	        label: 'To Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_no_of_days",
	        label: 'No of leave Days',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{value}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_apply_date_timestamp",
	        label: 'Applied On',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	}, 
      	{
	        name: "id",
	        label: 'Actions',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		<button className="ax-button" onClick = {() => onHandleApproveReject(value, true)}>
		              			<img src={approve} style={{width: '100px'}} />
		              		</button>
		              		<button className="ax-button mx-3" onClick = {() => onHandleApproveReject(value, false)}>
		              			<img src={reject} style={{width: '100px'}} />
		              		</button>
						</div>
		            );
	          	}
	        }
      	}
    ];

    const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 100,
	    sortOrder: {
	      name: '',
	      direction: 'asc'
	    }
  	};

  	const onHandleApproveReject = (id = "", approve = false) => {
  		//get The Leave from list
  		const filterLeave = leaves.filter((item, idx) => item.id = id)
  		if(filterLeave && filterLeave.length > 0) {
  			setApprove(approve);
  			setSelectedLeave(filterLeave[0]);
  			setShowApproveRejectDialog(true);
  		}
  	}

  	useEffect(() => {
  		setPendingLeaveList(leaves);
  	}, [leaves]);

  	const onDialogClose = (state = "") => {

  		setShowApproveRejectDialog(false);
  		if(state !== "") {
  			onFetchLeaveDetails();
  		}
  	}

	return (
		<div className="my-4">
      		<TableView
              clsName = "employee"
              title="" 
              columns={columns} 
              data={pendingLeaveList} 
              tableOptions = {options} 
            />

            {isShowApproveRejectDialog && 
            	<ApproveRejectLeaveDialog
            		isOpen = {isShowApproveRejectDialog}
            		selectedLeave = {selectedLeave}
            		isApprove = {isApprove}
            		mgr_id = {mgr_id}
            		onHandleCloseDialog = {(state) => onDialogClose(state)}
            	/>
            }
		</div>
	)
}

export default PendingLeaves;