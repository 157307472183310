import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import {baseURL} from '../../actions/Constants';
import TextAreaView from '../../components/TextAreaView';
import Loading from '../../components/loading/Loading';
import AttendanceTemplate from './AttendanceTemplate';
import emp_icon from '../../assets/images/emp.png';
import circle_logo from '../../assets/images/circle.png';

const ApproveRejectAttendanceDialog = (props) => {
    const { isOpen = false , isApprove = false, selectedAttendance = {}, mgr_id = "", onHandleCloseDialog} = props;
   
    const [attendance, setAttendance] = useState(selectedAttendance);
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [comments, setComments] = useState('');

    const title = `${isApprove ? 'Approve' : 'Reject'} Attendance`;

    const handleClose = (state = "") => {
       // if (reason && reason == "backdropClick") return;
        onHandleCloseDialog(state);
    };

    const onInputChange = (e) => {
        setAttendance({...attendance, [e.target.name] : e.target.value});
    }

    useEffect(() => {
        setAttendance(selectedAttendance);
    }, [selectedAttendance]);

    const onHandleSubmit = (e) => {
        e.preventDefault();
        onUpdateAttendanceApproveRejectChange();
    }

    const onUpdateAttendanceApproveRejectChange = async () => {
        setLoading(true);
        const URL = `${baseURL}/updateAttendanceModificationApproval`;

        const obj = {};

        obj.manual_edit_approve_date_timestamp = "" + new Date().getTime();
        obj.attendance_date_timestamp = selectedAttendance?.attendance_date_timestamp;
        obj.manual_edit_approve_status = isApprove ? "APPROVED": "REJECTED";
        obj.manual_edit_approver_remarks = comments;
        obj.emp_id = selectedAttendance?.emp_id;

        console.log("onUpdateAttendanceApproveRejectChange --->", obj);

        return await fetch(URL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(obj), // body data type must match "Content-Type" header
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const resp = response;
            const {status = ""} = resp;
            
            setLoading(false);

            if(status === 200 || status === 201) {
                handleClose('success');
            } else {
                //Error
                setError(true);
            }
        })
        .catch((error) => {
            //console.log('error onSelectedModel', error);
            setLoading(false);
            setError(true);
        });
    };

	return (
		<>
            {isLoading ? <Loading /> :
                (
                <Dialog 
                    fullWidth={true}
                    maxWidth={'md'}
                    open={isOpen}
                    onClose={() => handleClose("")}
                    aria-label=""
                    className="md"
                   // onBackdropClick="false"
                >
                    <div className="container p-lg-5 p-3">
                        <div className="d-flex align-items-center">
                            <img src={circle_logo} className="circle_logo" alt ="Logo" />
                            <div className="logo_black ps-3"><span className="logo_red">One</span> Asset</div> 
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div> 
                                      <img src={emp_icon} className="pe-2" alt="" /> 
                                    </div>

                                    <div>
                                        <div className="dialog_h2_heading">{title}</div>
                                        <div className="dialogSubText mt-3">
                                            <div>Please provide the reason to {title} request</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={(e) => onHandleSubmit(e)}>
                            <div className="mt-4">
                                <label>Comments</label>
                                <TextAreaView 
                                    label = "" 
                                    name="comments"
                                    value={comments}
                                    placeholder = "comments"
                                    onChange={(e) => setComments(e.target.value)}      
                                />
                            </div>

                            <div className="w-100 d-flex justify-content-end mt-3">
                                <button type="button" className="btn btn-outline-danger mt-3" onClick={() => handleClose("")}>Cancel</button>
                                <button type="submit" className="btn btn-danger mt-3 ms-3">{isApprove ? "Approve" : "Reject"}</button>
                            </div>
                        </form>

                    </div>                   
                </Dialog>
                )
            }

            {isLoading && <Loading />}

            {isError && <CustomizedSnackbars />}
        </>
	)
};

export default ApproveRejectAttendanceDialog;