import React, {useState, useEffect} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PCC_OneAttendance_version} from './actions/Constants';
import {isEmptyJson} from './util/utils';
//import {OverAllAccessList} from './util/DefaultInitialValues';

const Protected = ({children}) => {

	const location = useLocation();

	const {
  	details,
  	loading,
  	error
	} = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  const {
    details: OverAllAccessList
  } = useSelector(({ webAccess: { details, loading, error } }) => ({ details, loading, error }));

	const [currentLocation, setLocation] = useState("");

	useEffect(() => {
    let locationVal = location?.pathname;
    setLocation(locationVal);
  }, [location]);

  const validatePath = () => {
    if(currentLocation === "" || currentLocation === "/") return true;

    if(!OverAllAccessList || isEmptyJson(OverAllAccessList)) return true;

    //console.log("validatePath  --->", OverAllAccessList);

    const userRole = details?.emp_role?.toLowerCase();
    //console.log(userRole)
    const filterAccessInx = OverAllAccessList?.accessControlList?.findIndex(eachPage => (eachPage?.page_url === currentLocation) || currentLocation.includes(eachPage?.page_url));
    //console.log("filterAccessInx", filterAccessInx)
    if(filterAccessInx != -1) {
      const filterPageDetails = OverAllAccessList?.accessControlList[filterAccessInx];
     
      const filterRoleIndex = OverAllAccessList?.accessControlList[filterAccessInx]?.permission.findIndex((permissionRole, idx) => permissionRole?.role === userRole);
      //console.log("filterRoleIndex", filterRoleIndex)
      if(filterRoleIndex !== -1) {
        const accessVal = filterPageDetails.permission[filterRoleIndex].access;
        return accessVal;
      }
      
      return false;
    }

    return true;
  }

  const redirectPath = () => {
    if (!isSameVersion) return "/login";
    const userRole = details?.emp_role?.toLowerCase();
    const isUserExist = userRole && userRole !== "" /*&& (userRole.includes('admin') || userRole.includes('manager'))*/;

    return isUserExist ? "/404" : "/login";
  };

  const app_version = localStorage.getItem("PCC_OneAttendance_version");
  const isSameVersion = (app_version && app_version === PCC_OneAttendance_version);

  const isUserPresent = (validatePath() && isSameVersion) ? true : false;

  return isUserPresent ? children : <Navigate to = {redirectPath()} replace />;
};

export default Protected;