import React, {useEffect, useState} from "react";
import TableView from '../../components/table/TableView';
import {getDateFromTimeStamp} from '../../util/DateTimeConverter';

const MobileLog = (props) => {
	const {logs = [], emp_id = ""} = props;
	const [logData, setLogData] = useState(logs);
	
	const columns = [
	    {
	        name: "logData",
	        label: 'Event Name',
	        options: {
	        	filter: true,
	        	sort: true,
	        	customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
	                	<div className="fw-bold">
	                  		{value}
	                	</div>
	              	);
	          	}
	        }
	    },
	    {
	        name: "timestamp",
	        label: 'Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	},
	    {
	        name: "datetime",
	        label: 'Time',
	        options: {
	        	filter: true,
	        	sort: true,
	        	customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
	                	<div className="fw-bold">
	                  		{value.split(" ")[1]}
	                	</div>
	              	);
	          	}
	        }
	    },
      	
      	
    ];

    const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 100,
	    sortOrder: {
	      name: 'datetime',
	      direction: 'desc'
	    }
  	};

  	useEffect(() => {
  		setLogData(logs);
  	}, [logs]);

  
	return (
		<div className="my-4">
      		<TableView
              clsName = "employee"
              title="" 
              columns={columns} 
              data={logData} 
              tableOptions = {options} 
            />

           
		</div>
	)
}

export default MobileLog;