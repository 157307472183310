import React, {useEffect, useState} from "react";
import TableView from '../../components/table/TableView';
import {getDateFromTimeStamp} from '../../util/DateTimeConverter';

const RejectLeaves = (props) => {

	const {leaves = [], mgr_id, onFetchLeaveDetails} = props;
	const [rejectedLeaveList, setRejectedLeaveList] = useState(leaves);

	const columns = [
	    {
	        name: "emp_id",
	        label: 'Emp ID',
	        options: {
	        	filter: true,
	        	sort: true,
	        	customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
	                	<div className="fw-bold">
	                  		{value}
	                	</div>
	              	);
	          	}
	        }
	    },
	    {
	        name: "emp_name",
	        label: 'Emp Name',
	        options: {
	        	filter: true,
	        	sort: true,
	        	customBodyRender: (value, tableMeta, updateValue) => {
	              	return (
	                	<div className="fw-bold">
	                  		{value}
	                	</div>
	              	);
	          	}
	        }
	    },
      	{
	        name: "leave_description",
	        label: 'Reason',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            return (
		            	<div>
		              		{value}
						</div>
		            );
	          	}
	        }
      	}, 
      	{
	        name: "leave_starting_date_timestamp",
	        label: 'From Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_ending_date_timestamp",
	        label: 'To Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_no_of_days",
	        label: 'No of leave Days',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{value}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_approver_remarks",
	        label: 'Comment',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {

		            return (
		            	<div>
		              		{value || "-"}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "leave_approve_date_timestamp",
	        label: 'Rejection Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            return (
		            	<div>
		              		{getDateFromTimeStamp(value)}
						</div>
		            );
	          	}
	        }
      	}
    ];

    const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 100,
	    sortOrder: {
	      name: '',
	      direction: 'asc'
	    }
  	};

	return (
		<div className="my-4">
      		<TableView
              clsName = "employee"
              title="" 
              columns={columns} 
              data={rejectedLeaveList} 
              tableOptions = {options} 
            />
		</div>
	)
}

export default RejectLeaves;