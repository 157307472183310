import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import TableView from '../../components/table/TableView';
import TabView from '../../components/tabView/TabView';
import WebAccessControl from './WebAccessControl';
import MobileAccessControl from './MobileAccessControl';

import {InitialFilters} from '../../util/DefaultInitialValues';
import access_control from '../../assets/images/Access_Control.png';

const AccessControl = () => {
	//const dispatch = useDispatch();
  	const titleVal = "Access Control - OneAttendance";
	const hrefVal = window.location.href;
	const [filters, setFilters] = useState(InitialFilters);

  	const TabListData = [WebAccessControl/*, MobileAccessControl*/];
  	const TabsData = ["Web"/*, "Mobile"*/];

	return (
		<>
			<Helmet>
		      	<title>{titleVal}</title>
		      	<meta name="description" content="Login meta"></meta>
		      	<link rel="canonical" href={hrefVal} />
	    	</Helmet>

	      	<div className="d-flex justify-content-between align-items-center mb-3">
		        <div className="d-flex align-items-center">
		          <div> 
		            <img src={access_control} className="w-100 pe-3" /> 
		          </div>

		          <div>
		            <div className="page_h1_heading">Access Control</div>
		            <div className="page_le_text">{filters && filters?.emp_legal_entity_id}</div>
		          </div>
		        </div>
	      	</div>

	      	<TabView TabsData = {TabsData} TabListData={TabListData} />
		</>
	)
}

export default AccessControl;