import React, {useEffect, useState} from 'react';
import { DateRangePicker, Stack } from 'rsuite';
import {getEpocFromMoment} from '../util/utils';
import moment from 'moment';

import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';


const DatePickerView = (props) => {
  const {onDatePickerUpdate, value = [], isMaxForMonth = true} = props;
  const [selectedDate, setSelectedDate] = useState([new Date(value[0]), new Date(value[1])]);
  const {allowedMaxDays, combine, afterToday} = DateRangePicker;

  useEffect(() => {
    onDatePickerUpdate(selectedDate);
  },[selectedDate]);

  useEffect(() => {
    if(getEpocFromMoment(selectedDate[0]) !== getEpocFromMoment(new Date(value[0]))
      || getEpocFromMoment(selectedDate[0]) !== getEpocFromMoment(new Date(value[0]))
    ) 
    {
      setSelectedDate([new Date(value[0]), new Date(value[1])])
    }
    
  }, [value]);

  const ranges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
   
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    }
  ];

  return (
    <div className="datepicker">
      <Stack direction="column" spacing={8} alignItems="flex-start" style={{width: '100%'}}>
        <DateRangePicker
          value={selectedDate}
          onChange={setSelectedDate}
          cleanable={false} 
          format='dd-MM-yyyy'
          style={{width: '100%'}}
          ranges={ranges}
          disabledDate={isMaxForMonth ? combine(allowedMaxDays(31), afterToday()) : null} 
        />
      </Stack>
    </div>
  );
};

export default DatePickerView;