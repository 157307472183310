import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SelectView from '../../components/Select/SelectView';
import {isUserHasFeatureAccess, isEmptyJson} from '../../util/utils';
import {saveFilters} from '../../actions/filters.actions.js';

const POSSelectView = (props) => {
	const {name="", title="POS", onInputChange, label="", value=""} = props;
    const saveFilterDataAction = saveFilters;
    const dispatch = useDispatch();

    const {
        details
    } = useSelector(({ office: { details} }) => ({ details}));

    const [webAccessFeatureList, setWebAccessFeatureList] = useState([]);

    const {
        details: filterDetails
    } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

    const {
      details: loggedAdminDetails
    } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

    const {
        details: webAccessList
    } = useSelector(({ webAccess: { details, loading, error } }) => ({ details, loading, error }));

    useEffect(() => {
        if(webAccessList && webAccessList?.featureControlList) {
            setWebAccessFeatureList(webAccessList?.featureControlList);
        }
    }, [webAccessList]);

    useEffect(() => {
        if(Array.isArray(details) && details.length === 1) {
            const ofcId = details[0]?.office_id; 
            setOfficeList(details); 
            setFilters({...filters, office_id: ofcId});
            setUpdateFilters(true);
            return;
        }

        const hasAccess = isUserHasFeatureAccess(loggedAdminDetails?.emp_role, webAccessFeatureList, "AODA");

        if(!hasAccess) {
           const filterList = details.filter(ofc => ofc?.office_id === loggedAdminDetails?.emp_base_office_id);
           setOfficeList(filterList);
           setFilters({...filters, office_id: loggedAdminDetails?.emp_base_office_id});
        } else {
            const ofcId = details.length === 1 ? details[0]?.office_id : ''; 
            setOfficeList(details); 
            setFilters({...filters, office_id: ofcId});
        }
        setUpdateFilters(true);
    }, [webAccessFeatureList]);

    const [officeList, setOfficeList] = useState(details);
    const [filters, setFilters] = useState(filterDetails);
    const [isUpdateFilters, setUpdateFilters] = useState(false);

    useEffect(() => {
        if(isUpdateFilters && filters && !isEmptyJson(filters) && filterDetails.toString() !== filters.toString()) {
            dispatch(saveFilterDataAction(filters));
            setUpdateFilters(false);
        }
    }, [isUpdateFilters, filters]);
    
	return (
		<SelectView
            label="POS"
            name={name}
            value={value}
            errorFieldName="POS"
            isInvalid={false}
            list={officeList}
            placeholder={title}
            keyVal="office_id"
            title="office_name"
            labelVal="office_name"
            onInputChange = {(e) => onInputChange(e)}
            isStandard= {false}
        />
	)
}

export default POSSelectView;