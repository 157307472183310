import {
  GET_WEB_ACCESS_CONTROL_START,
  GET_WEB_ACCESS_CONTROL_SUCCESS,
  GET_WEB_ACCESS_CONTROL_FAILURE,
  baseURL
} from './Constants';

export const updateWebAccessControlByLE = (le, ac) => async (dispatch) => { //Payroll
  if(!le) return;

  dispatch({
    type: GET_WEB_ACCESS_CONTROL_START,
    error: null
  });
  
  const URL=`${baseURL}/updateWebAccessControlByLE?legalEntityId=${le}`;
  
  return await fetch(URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(ac), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    const data_json = res && res[0] && res[0]?.legal_entity_web_access_control || {};
    const data = JSON.parse(data_json);
    dispatch({
      type: GET_WEB_ACCESS_CONTROL_SUCCESS,
      payload: data,
      error: null
    });
  })
  .catch((error) => {
    console.log('error getAllOfficesByLegalEntity', error);
    dispatch({
      type: GET_WEB_ACCESS_CONTROL_FAILURE,
      error: error,
    });
  });
}

export const getWebAccessControlByLE = le => async (dispatch) => { //Payroll
  if(!le) return;

  dispatch({
    type: GET_WEB_ACCESS_CONTROL_START,
    error: null
  });

  let obj = {};
  obj.legal_entity_id = le;
  
  const URL=`${baseURL}/getWebAccessControlByLE`;
  
  return await fetch(URL, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    //console.log("getWebAccessControlByLE -->", res)
    const data_json = res && res[0] && res[0]?.legal_entity_web_access_control || {};
    const data = JSON.parse(data_json);
    
    dispatch({
      type: GET_WEB_ACCESS_CONTROL_SUCCESS,
      payload: data,
      error: null
    });
  })
  .catch((error) => {
    console.log('error getWebAccessControlByLE', error);
    dispatch({
      type: GET_WEB_ACCESS_CONTROL_FAILURE,
      error: error,
    });
  });
}
