import { Business } from "@mui/icons-material";
import { PersonAdd, AccessAlarm, DocumentScanner } from "@mui/icons-material";
//import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import leave from '../assets/images/ic_leave_approval.png';
import access_Control_Icon from '../assets/images/Access_Control_Icon.png';
import attendance_approval from '../assets/images/attendance-approval.png';

const sidebarNav = [
    
    {
        link: '/report',
        section: 'report',
        icon: <i className='bx bx-receipt' ></i>,
        text: 'report'
    },
    {
        link: '/employes',
        section: 'employes',
        icon: <i className='bx bx-user'></i>,
        text: 'Employes'
    },
    {
        link: '/offices',
        section: 'offices',
        icon: <Business />,
        text: 'Offices'
    },
    {
        link: '/leaves',
        section: 'leaves',
        icon: <img src={leave} />,
        text: 'Leaves'
    },
    {
        link: '/attendance-approval',
        section: 'attendance-approval',
        icon: <img src={attendance_approval} />,
        text: 'Leave Approval'
    },
    {
        link: '/access-control',
        section: 'access-control',
        icon: <img src={access_Control_Icon} />,
        text: 'Access Control'
    },
    {
        link: '/roster',
        section: 'roster',
        icon: <AccessAlarm />,
        text: 'Roster'
    },
    {
        link: '/logs',
        section: 'logs',
        icon: <DocumentScanner />,
        text: 'Log'
    },
    {
        link: '/settings',
        section: 'settings',
        icon: <i className='bx bxs-cog'></i>,
        text: 'Settings'
    }
]

export default sidebarNav