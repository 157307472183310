import MUIDataTable from "mui-datatables";
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

/*
import Coupon from '../../assets/images/ticket-discount.png';
import SMS from '../../assets/images/SMS.png';
import Whatsapp from '../../assets/images/whatsapp.png';
*/

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

const TableView = (props) => {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(false);

  const {title = "", columns = [], data = [], onSMSBtnClicked, tableOptions = {}, clsName = ""} = props;

  const handleRowSMSSelected = (selectedRows) => {
    onSMSBtnClicked(selectedRows);
  }

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectToolbarPlacement: 'above',
    searchAlwaysOpen: false,
    rowsPerPage: 15,
    
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <div>
        <button className="btn btn-danger px-3 mx-2" onClick = {() => handleRowSMSSelected(selectedRows)}>
          {/*<img src = {SMS} style={{width: '29px', padding: '4px'}}/>*/}
          Modify Time
        </button>
      </div>
    ),

    /*selectableRows: false,*/
    onTableChange: (action, state) => {
      
    },
    onRowsDelete: (rowsDeleted, newData) => {
     
    }
  };

  const finalOptions = {...options, ...tableOptions }

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme()}>
        <MUIDataTable
          className = {`${clsName}`}
          title={title}
          data={data}
          columns={columns}
          options={finalOptions}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default TableView;