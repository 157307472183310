import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Helmet } from 'react-helmet';
import {baseURL} from '../../actions/Constants';

import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import FilterView from '../../components/filterView/FilterView';
import Loading from '../../components/loading/Loading';
import Table from '../../components/table/Table';
import Tooltip from '@mui/material/Tooltip';
import {isEmptyJson} from '../../util/utils';

import {getDateFromTimeStamp, reverseDate} from '../../util/DateTimeConverter';
import {getAllOfficesByLegalEntity} from '../../actions/offices.actions.js';
import {InitialOffice} from '../../util/DefaultInitialValues';
import EditOffice from './EditOffice';
import { Business } from "@mui/icons-material";

import config_settings from '../../assets/images/Config_settings.png';

const Offices = () => {
    const dispatch = useDispatch();
    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [offices, setOffices] = useState([]);
    const [isShow, setShow] = useState(false);
    const [ofcDetails, setOfcDetails] = useState(null);
    const [filters, setFilters] = useState(null);

    const {
      details
    } = useSelector(({ filter: { details, loading, error } }) => ({ details, loading, error }));

    const {
      details : officeDetails
    } = useSelector(({ office: { details, loading, error } }) => ({ details, loading, error }));


    useEffect(() => {
      if(details && !isEmptyJson(details)) {
        setFilters(details); 
      }
    }, [details]);

    useEffect(() => {
      if(officeDetails && !isEmptyJson(officeDetails)) {
        setOffices(officeDetails); 
      }
    }, [officeDetails]);

    useEffect(() => {
      document.title = "Office List - PC Chandra";
    }, []);

    useEffect(() => {
      if(filters !== null && emp_legal_entity_id !== "") {
        dispatch(getAllOfficesByLegalEntity(emp_legal_entity_id));
      }
    }, [filters]);

    const onUpdateSuccess = () => {
      setShow(false);
      dispatch(getAllOfficesByLegalEntity(emp_legal_entity_id));
    }

    const showDetails = (value) => {
      if(value !== null) {
        const filterRow = offices.filter((item, index) => item.office_id === value);
        
        if(filterRow && filterRow.length > 0) {
          const details = filterRow[0];
          let ofcConfigDetails = details.office_configuration;
          let geoFencingDetails = details.office_geofencing_conf;
          let wifiConfigDetails = details.office_wifi_conf;

          if(details && ofcConfigDetails !== "") {
            ofcConfigDetails = JSON.parse(details.office_configuration);
          } 

          if(details && details.office_geofencing_conf !== "") {
            geoFencingDetails = JSON.parse(details.office_geofencing_conf);
          } 

          if(details && wifiConfigDetails !== "") {
            wifiConfigDetails = JSON.parse(details.office_wifi_conf);
          } 

          const obj = {...details, office_configuration: ofcConfigDetails, office_geofencing_conf: geoFencingDetails, office_wifi_conf: wifiConfigDetails};
          setOfcDetails(obj);
        }
      } else {
        setOfcDetails(InitialOffice);
      }
      setShow(true);
    }

    const columns = [
        {
          name: "office_id",
          label: 'ID',
          options: {
            filter: false,
            sort: true,
            viewColumns: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <button onClick={() => showDetails(value)} className="ax-button red">
                    {value}
                  </button>
                );
            }
          }
        },
        {
          name: "office_name",
          label: 'Name',
          options: {
           filter: true,
           sort: true,
          }
        },
        {
          name: "office_email",
          label: 'Office Email',
          options: {
           filter: true,
           sort: true,
          }
        },
        {
          name: "office_address",
          label: 'Address',
          options: {
           filter: true,
           sort: true
          }
        },
        {
          name: "office_phone",
          label: 'Phone #',
          options: {
           filter: true,
           sort: true,
          }
        },
        {
          name: "office_description",
          label: 'Description',
          options: {
           filter: true,
           sort: true
          }
        }
    ];

    const AddButton = () => (
      <Tooltip disableFocusListener title='Add Office'>
        <button className="MuiButtonBase-root MuiIconButton-root ax-button" onClick={() => showDetails(null)} >
          <Business />
        </button>
      </Tooltip>
    );

    const options = {
      selectableRows: false,
      filter: false,
      download: true,
      print: true,
      rowsPerPage: 100,
      customToolbar: AddButton,
      sortOrder: {
        name: 'emp_device_uuid',
        direction: 'asc'
      },
      downloadOptions: {
        filename: `PCC-Employees-${reverseDate(getDateFromTimeStamp(Date.now()))}.csv`,
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        }
      }
    };

    const onInputChange = (e) => console.log(e);
    const titleVal = "Offices - OneAttendance";
    const le = details && details.emp_legal_entity_id || "SMMC";

    const {
      office_id = "",
      emp_legal_entity_id = "",
      emp_id_pattern = "",
      date = ""
    } = filters || {};

    return (
      <>
        <Helmet>
        <title>{titleVal}</title>
          <meta name="description" content="Login meta"></meta>
        </Helmet>

        {isLoading ? <Loading /> :
        (
          <div className="home">
            <div className="container-fluid">
              <div className="row align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex align-items-center">
                        <div> 
                          <img src={config_settings} className="page_logo" /> 
                        </div>

                        <div>
                          <div className="page_h1_heading">Offices</div>
                          <div className="page_le_text">{filters && filters.emp_legal_entity_id}</div>
                        </div>
                        
                      </div>
                   
                  </div>
                   
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <FilterView filters={filters} onHandleGo={data => setFilters(data)} />
                  </div>
              </div>
                  
              <div className="row mt-2">
                  <div className="col-lg-12">
                    <Table headers={columns} rowData={offices} options={options} />
                  </div>
              </div>
            </div>
            {isShow && <EditOffice 
              details={ofcDetails} 
              isOpen = {isShow} 
              onUpdateSuccess={onUpdateSuccess} 
              onHandleCloseDialog = {() => setShow(false)} le = {le} 
            />}
          </div>
          
        )}
        {isError && <CustomizedSnackbars />}
      </>
    );
}
 
export default Offices;