import React, {useEffect, useState} from "react";
import ApproveRejectAttendanceDialog from './ApproveRejectAttendanceDialog';
import TableView from '../../components/table/TableView';

import {getFormattedTime} from '../../util/utils';
import {msToTime, reverseDate} from '../../util/DateTimeConverter';

//import approve from '../../assets/images/approve.png';
//import reject from '../../assets/images/reject.png';
import people from '../../assets/images/people.png';
import approve from '../../assets/images/ic_tick2.png';
import reject from '../../assets/images/ic_cross.png';

const PendingAttendance = (props) => {

	const {attendance = [], fetchAttendanceDetails} = props;
	const [pendingAttendanceList, setPendingAttendanceList] = useState(attendance);
	const [selectedAttendance, setSelectedAttendance] = useState(null);
	const [isShowApproveRejectDialog, setShowApproveRejectDialog] = useState(false);
	const [isApprove, setApprove] = useState(false);

	const onHandleApproveReject = (atn = {}, approve = false) => {
		//get The Leave from list
		if(atn && atn?.emp_id) {
			setApprove(approve);
			setSelectedAttendance(atn);
			setShowApproveRejectDialog(true);
		}
	}

	useEffect(() => {
		setPendingAttendanceList(attendance);
	}, [attendance]);

	const onDialogClose = (state = "") => {
		setShowApproveRejectDialog(false);
		if(state !== "") {
			fetchAttendanceDetails();
		}
	}

	const columns = [
	    {
	      name: "emp_id",
	      label: 'Emp ID',
	      options: {
	        filter: true,
	        sort: true,
	        viewColumns : false,
	        customBodyRender: (value, tableMeta, updateValue) => {
	         
	          const selectedEmp = attendance.find(item => item?.emp_id === value) || {};

	          return (
	            <div className="fw-bold d-flex align-items-center">
	              <img src={selectedEmp?.emp_profile_pic || people} style={{width: '40px', height: '40px'}} className="rounded-circle" alt="" />
	              <div className="mx-3">
	                <div className="fw-bold">{value}</div>
	                <div className="fw-bold">{selectedEmp?.emp_name}</div>
	              </div>
	            </div>
	          );
	        }
      	  }
    	},
      	{
	        name: "attendance_date_timestamp_formatted",
	        label: 'Date',
	        options: {
	        	filter: true,
	          	sort: true,
	          	viewColumns : false,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            return (
		            	<div>
		              		{reverseDate(value)}
						</div>
		            );
	          	}
	        }
      	}, 
      	{
	        name: "attendance_trail",
	        label: 'Recorded In - OUT Time',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
	          		const trail = JSON.parse(value);
					const {sign_in = 0, sign_out = 0}  = trail?.login_trail?.[0] || {};
					
		            return (
		            	<div className="d-flex align-items-center">
		              		{getFormattedTime(sign_in)} <span className="fs-5 px-2">-</span> {getFormattedTime(sign_out)}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "attendance_trail",
	        label: 'Recorded Duration',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
	          		const trail = JSON.parse(value);
					
					const {sign_in = 0, sign_out = 0}  = trail?.login_trail?.[0] || {};
					const actual_duration_val = (sign_in && sign_out && sign_in < sign_out) ? msToTime(sign_out, sign_in) : '--:--';
	
		            return (
		            	<div>
		              		{actual_duration_val}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "manual_edit_status",
	        label: 'Manual In - OUT Time',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
	          		const {manual_sign_in = "", manual_sign_out = ""} = JSON.parse(value);
	          		
		            return (
		            	<div className="d-flex align-items-center">
		              		{getFormattedTime(manual_sign_in)} <span className="fs-5 px-2">-</span> {getFormattedTime(manual_sign_out)}
						</div>
		            );
	          	}
	        }
      	},
      	{
	        name: "manual_edit_status",
	        label: 'Manual Duration',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            const {manual_sign_in = "", manual_sign_out = ""} = JSON.parse(value);
	          		const modified_duration_val = (manual_sign_in && manual_sign_out && manual_sign_in < manual_sign_out) ? msToTime(manual_sign_out, manual_sign_in) : "--:--";
	
		            return (
		            	<div className="d-flex align-items-center">
		              		{modified_duration_val}
						</div>
		            );
	          	}
	        }
      	}, 
      	{
	        name: "manual_edit_reason",
	        label: 'Reason',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
		            return (
		            	<div>
		              		{value || "-"}
						</div>
		            );
	          	}
	        }
      	},
      	
      	{
	        name: "id",
	        label: 'Actions',
	        options: {
	        	filter: true,
	          	sort: true,
	          	customBodyRender: (value, tableMeta, updateValue) => {
	          		const {rowData = []} = tableMeta;
	          		const id = rowData[0];
	          		const date = rowData[1];

	          		const atn = pendingAttendanceList.find(item => item?.emp_id === id && item?.attendance_date_timestamp_formatted === date);
		            return (
		            	<div>
		              		<button className="ax-button" onClick = {() => onHandleApproveReject(atn, true)}>
		              			<img src={approve} style={{width: '45px'}} alt = "" />
		              		</button>
		              		<button className="ax-button mx-3" onClick = {() => onHandleApproveReject(atn, false)}>
		              			<img src={reject} style={{width: '40px'}} alt = "" />
		              		</button>
						</div>
		            );
	          	}
	        }
      	}
    ];

    const options = {
	    selectableRows: false,
	    filter: false,
	    download: true,
	    print: true,
	    rowsPerPage: 15,
	    sortOrder: {
	      name: 'attendance_date_timestamp',
	      direction: 'asc'
	    }
  	};

	return (
		<div className="my-4">
  		{/*
  			pendingAttendanceList.length > 0 ? pendingAttendanceList.map((item, idx) => <AttendanceTemplate attendance = {item} idx = {idx} onHandleApproveReject = {onHandleApproveReject} />)
  		  : <div className="fss-6">No attendance found</div>
      	*/}

      	<TableView
        	clsName = "employee"
          	title="" 
          	columns={columns} 
          	data={pendingAttendanceList} 
          	tableOptions = {options} 
        />

      	{isShowApproveRejectDialog && 
      	<ApproveRejectAttendanceDialog
      		isOpen = {isShowApproveRejectDialog}
      		selectedAttendance = {selectedAttendance}
      		isApprove = {isApprove}
      		onHandleCloseDialog = {(state) => onDialogClose(state)}
      	/>
      	}
		</div>
	)
}

export default PendingAttendance;